'use strict';

import React from 'react';
import Select, {components} from 'react-select';
import _ from 'underscore';
import {FormLabel} from '../../components/form/FormLabel';
import {selectStyle} from '../../components/form/uptime-select-styles';
import Ajax from '../../jskit/general/Ajax';
import Formatter from '../../jskit/general/Formatter';
import Utils from '../../jskit/general/Utils';
import Modal from '../../jskit/react/Modal';
import ReactUtils from '../../jskit/react/ReactUtils';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import CheckBox from '../../jskit/react/forms/CheckBox';
import {FieldErrors, FormErrors, HelpIcon, Label, prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import FormWarning from '../../jskit/react/forms/FormWarning';
import PercentageInput from '../../jskit/react/forms/PercentageInput';
import RadioGroup from '../../jskit/react/forms/RadioGroup';
import Select2 from '../../jskit/react/forms/Select2';
import ShowPassword from '../../jskit/react/forms/ShowPassword';
import Slider from '../../jskit/react/forms/Slider';
import SplitInterval from '../../jskit/react/forms/SplitInterval';
import TextArea from '../../jskit/react/forms/TextArea';
import TextInput from '../../jskit/react/forms/TextInput';
import ZendeskSupportLink from '../../jskit/react/forms/ZendeskSupportLink';
import TransactionScriptEditor from '../../transactions/TransactionScriptEditor';
import {GroupUptimePercentCalculation, SingleServiceSelectBox, contactGroupsAllowed} from '../common/DevicesCommon';
import ServiceSelectorDialog from '../common/ServiceSelectorDialog';
import ServiceSelectorInput from '../common/ServiceSelectorInput';
import {TaskStatus} from '../common/TaskStatus';
import TestRunner from '../common/TestRunner';
import InstantCheckout from './InstantCheckout.jsx';
import ServiceEscalations from './ServiceEscalations.jsx';
import ServiceMaintenance from './ServiceMaintenance.jsx';

import $ from 'jquery';

export const Pages = {
  PAGE_BASIC: 'page_basic',
  PAGE_ADVANCED: 'page_advanced',
  PAGE_ESCALATIONS: 'page_escalations',
  PAGE_MAINTENANCE: 'page_maintenance',
};

const Sections = {
  SECTION_REQUIRED: 'section_required',
  SECTION_OPTIONAL: 'section_optional',
};

export default class ServiceForm extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    this.state = {
      formData: {},
      originalFormData: {},
      autoloadErrors: null,
      currentPage: null,
      currentSection: null,
      testStatus: null,
      testResults: null,
      testServer: null,
      checksSelectorIsActive: false,
      // form never unmounts, emulate by session ID
      sessionID: String(Math.random()),
    };

    this.ALL_PAGES = [
      [Pages.PAGE_BASIC, 'Basic'],
      [Pages.PAGE_ADVANCED, 'Advanced'],
      [Pages.PAGE_ESCALATIONS, 'Escalations'],
      [Pages.PAGE_MAINTENANCE, 'Maintenance'],
    ];

    this.ALL_SECTIONS = [
      [Sections.SECTION_REQUIRED, 'Required'],
      [Sections.SECTION_OPTIONAL, 'Optional'],
    ];

    this.SplitIntervalChecks = ['HEARTBEAT', 'PAGESPEED'];
    this.TxnRecorderChecks = ['TRANSACTION'];

    if (props.hasTransactionExtEnabled) {
      this.SplitIntervalChecks.push('TRANSACTION');
    }
  }

  getFormData() {
    return this.state.formData;
  }

  setFormData(state, callback) {
    const newFormData = Object.assign({}, this.getFormData(), state);
    this.state.formData = newFormData;
    this.setState({formData: newFormData}, callback);
  }

  showModal(isAddingNewItem, state, currentPage) {
    if (state) {
      this.setState({
        formData: state,
        originalFormData: Object.assign({}, state),
      });
    }

    if (isAddingNewItem) {
      this.setState({}, function () {
        this.loadFieldDefaultsFromServiceDef(this.getFormData().monitoring_service_type);
      });
    }

    this.setState({
      currentPage: currentPage || Pages.PAGE_BASIC,
      currentSection: Sections.SECTION_REQUIRED,
      sessionID: String(Math.random()),
    });
    this.clearAutoloadErrors();
    this.resetRunTest();
    this.updateEmbeddedEditors(true);
    this.refs.modal.showModal();
  }

  hideModal() {
    this.refs.modal.hideModal();
  }

  handleModalCancel() {
    if (this.props.goBackOnCancel) {
      setTimeout(() => history.back(), 300);
    }
  }

  goToFirstPage() {
    this.handlePageChange(Pages.PAGE_BASIC);
  }

  getServiceDef(monitoringServiceType) {
    if (!this.props.choices.service_defs) {
      return null;
    }

    monitoringServiceType = monitoringServiceType || this.getFormData().monitoring_service_type;
    return monitoringServiceType ? this.props.choices.service_defs[monitoringServiceType] : null;
  }

  scrollSnippetIntoView() {
    if (window.location.hash) {
      const anchor = document.querySelector(window.location.hash);
      if (anchor) {
        anchor.scrollIntoView();
      }
    }
  }

  resetRunTest() {
    this.setState({
      testResults: null,
      testStatus: null,
      testServer: null,
    });
  }

  isFieldVisible(fieldName) {
    const {formData} = this.state;
    const serviceDef = this.getServiceDef(formData.monitoring_service_type);
    if (!(serviceDef && serviceDef.fields)) {
      return false;
    }
    const {fields} = serviceDef;
    return _.findWhere(fields, {field: fieldName, visible: true}) !== undefined;
  }

  isMonitoringGroupsFieldVisible() {
    const formDef = this.getServiceDef();

    if (!formDef || !this.props.choices.monitoring_groups) {
      return false;
    }

    const hasPrivateLocations = this.props.choices.monitoring_groups.some((mg) => mg.is_private);
    const groupType = formDef.force_monitoring_group_type;

    if (groupType === 'DISABLED' || (groupType === 'SINGLE_LOCATION_CHECKS' && !hasPrivateLocations)) {
      return false;
    }
    return true;
  }

  isFieldIncludedInCheckVersion(fieldInfo, formData) {
    // Check if a field should be excluded from specific versions
    // of a check, based on the service def.
    if (!formData.msp_version || !fieldInfo.versions) {
      return true;
    }

    return fieldInfo.versions.indexOf(parseInt(formData.msp_version)) !== -1;
  }

  getErrorsToDisplay() {
    return this.state.autoloadErrors || this.props.errors;
  }

  clearAutoloadErrors() {
    this.setState({autoloadErrors: null});
  }

  listTestLocations(allow_public) {
    let monitoring_servers;
    if (allow_public) {
      monitoring_servers = this.props.choices.run_test_monitoring_servers;
    } else {
      monitoring_servers = this.props.choices.run_test_monitoring_servers.filter((s) => s.is_private || s.is_test);
    }

    const formData = this.getFormData();
    monitoring_servers = this.filterAllowedMonitoringServersForCheckType(
      formData.monitoring_service_type,
      formData.msp_version,
      monitoring_servers
    );

    return monitoring_servers;
  }

  filterAllowedMonitoringGroupsForCheckType(monitoringServiceType, mspVersion, monitoringGroups, getIdFn) {
    // TODO: BELGRADE: This filtering should be removed once NAGIOS has been fully decommissioned.
    const serviceDef = this.getServiceDef(monitoringServiceType);
    const version = parseInt(mspVersion || serviceDef.versions.at(-1).version);
    const allowedMonitoringGroups = this.props.choices.monitoring_groups_arch_mapper.find(
      (x) => x.monitoring_service_type === monitoringServiceType && x.version === version
    );
    if (allowedMonitoringGroups) {
      monitoringGroups = monitoringGroups.filter((mg) =>
        allowedMonitoringGroups.monitoring_group_ids.includes(getIdFn(mg))
      );
    }
    return monitoringGroups;
  }

  filterAllowedMonitoringServersForCheckType(monitoringServiceType, mspVersion, monitoringServers) {
    // TODO: BELGRADE: This filtering should be removed once NAGIOS has been fully decommissioned.
    const serviceDef = this.getServiceDef(monitoringServiceType);
    const version = parseInt(mspVersion || serviceDef.versions.at(-1).version);
    const allowedMonitoringGroups = this.props.choices.monitoring_groups_arch_mapper.find(
      (x) => x.monitoring_service_type === monitoringServiceType && x.version === version
    );
    if (allowedMonitoringGroups) {
      monitoringServers = monitoringServers.filter((ms) => {
        if (!allowedMonitoringGroups.monitoring_group_ids.includes(ms.group_id)) {
          return false;
        }
        if (ms.is_nagios && !allowedMonitoringGroups.has_nagios) {
          return false;
        }
        if (!ms.is_nagios && !allowedMonitoringGroups.has_belgrade) {
          return false;
        }
        return true;
      });
    }
    return monitoringServers;
  }

  loadFieldDefaultsFromServiceDef(monitoringServiceType) {
    const serviceDef = this.getServiceDef(monitoringServiceType);
    const formData = this.getFormData();
    const newFormData = {
      msp_version: serviceDef.versions.at(-1).version,
    };
    _.each(serviceDef.fields, (field) => {
      // We're changing check types, so we want to set the default value
      // for each relevant check field that is currently blank.
      // For most check types we don't want to overwrite a previously
      // entered user value, except for threshold, response_time_sla and version.
      // The defaults for these two fields are so different between check
      // types that any previously entered value is very likely irrelevant,
      // and better to overwrite with the default for the new check type.
      if (
        field['default'] !== null &&
        (formData[field.field] === undefined ||
          formData[field.field] === '' ||
          field.field === 'msp_version' ||
          field.field === 'msp_threshold' ||
          field.field === 'msp_response_time_sla')
      ) {
        newFormData[field.field] = field['default'];
      }
    });
    const {monitoring_service_type: mst} = formData;
    const {fields} = this.props.choices.service_defs[mst];
    const msp_interval = fields.find((f) => f.field === 'msp_interval' && !!f.mapped_fields.frequency) || {
      default: this.props.choices.msp_interval.default,
      mapped_fields: {frequency: {}},
    };
    const min_interval =
      msp_interval.mapped_fields.frequency[this.props.checkFrequency] || this.props.choices.msp_interval.min;
    newFormData.msp_interval = Math.max(formData.msp_interval || msp_interval.default, min_interval);

    const groupType = serviceDef.force_monitoring_group_type || 'NORMAL';
    newFormData['monitoring_groups'] = _.clone(this.props.choices.default_monitoring_groups[groupType]);

    newFormData['monitoring_groups'] = this.filterAllowedMonitoringGroupsForCheckType(
      monitoringServiceType,
      newFormData['msp_version'],
      newFormData['monitoring_groups'],
      (mgid) => mgid
    );

    this.setFormData(newFormData);
  }

  updateEmbeddedEditors(forceUpdate) {
    this.setState({}, function () {
      const serviceDef = this.getServiceDef() || {};
      const formData = this.getFormData();

      if (forceUpdate) {
        // For initial load, clear out the transaction editor & escalations editor
        this.refs.transactionScriptEditor.deserialize(formData.monitoring_service_type, null);
        this.refs.escalations.deserialize(formData.escalations);
        this.refs.maintenance.deserialize(formData);
      }

      if (serviceDef.has_transaction_script) {
        // Going to a transaction check, deserialize any valid
        // msp_script into the transaction steps.
        this.refs.transactionScriptEditor.deserialize(formData.monitoring_service_type, formData.msp_script);
      }
    });
  }

  handleTypeChanged(e) {
    const newType = e.target.value;
    this.clearAutoloadErrors();
    this.resetRunTest();
    this.setState({currentSection: Sections.SECTION_REQUIRED});
    this.setFormData({msp_script: ''});
    this.loadFieldDefaultsFromServiceDef(newType);
    this.updateEmbeddedEditors();
    this.props.resetValidationErrorsFn();
  }

  handleSave(e, callback) {
    e.preventDefault();

    this.clearAutoloadErrors();
    this.updateEmbeddedEditors();
    this.props.resetValidationErrorsFn();

    this.updateTransactionScript();

    this.setFormData(
      Object.assign({}, {escalations: this.refs.escalations.serialize()}, this.refs.maintenance.serialize()),
      callback || this.props.onSave
    );
  }

  handleDNSAutoload(e) {
    e.preventDefault();
    this.clearAutoloadErrors();

    new Ajax().post({
      url: this.props.extraFormProps.dnsCheckURL,
      data: _.extend(
        {function: 'autoload-expect-string'},
        _.pick(this.getFormData(), 'msp_address', 'msp_dns_server', 'msp_dns_record_type')
      ),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.setFormData(data.data);
        } else {
          this.setState({autoloadErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleWhoisAutoload(e) {
    e.preventDefault();

    this.clearAutoloadErrors();

    new Ajax().post({
      url: this.props.extraFormProps.whoisCheckURL,
      data: _.extend({function: 'autoload-expect-string'}, _.pick(this.getFormData(), 'msp_address')),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.setFormData(data.data);
        } else {
          this.setState({autoloadErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleAddressBlur(e) {
    const formData = this.getFormData();

    if ('DNS' === formData.monitoring_service_type) {
      if (formData.msp_address && !formData.msp_dns_server && !formData.msp_expect_string) {
        this.handleDNSAutoload(e);
      }
    } else if ('WHOIS' === formData.monitoring_service_type) {
      if (formData.msp_address) {
        this.handleWhoisAutoload(e);
      }
    }
  }

  handlePageChange(page) {
    this.setState({currentPage: page});
  }

  handleSectionChange(section) {
    this.setState({currentSection: section});
  }

  handleOpenRecorder(e) {
    const formData = this.getFormData();
    if (this.TxnRecorderChecks.includes(formData.monitoring_service_type)) {
      this.handleSave(e, () => {
        this.props.onSave((data) => {
          window.location = this.props.extraFormProps.transactionRecorderURL + '?service=' + data.id;
        });
      });
    }
  }

  getTransactionScript() {
    const serviceDef = this.getServiceDef() || {};
    if (serviceDef.has_transaction_script) {
      return this.refs.transactionScriptEditor.serialize();
    }
    return null;
  }

  updateTransactionScript() {
    this.setFormData({
      msp_script: this.getTransactionScript(),
    });
  }

  handleRunTestStart() {
    this.clearAutoloadErrors();
    this.updateEmbeddedEditors();
    this.props.resetValidationErrorsFn();
    this.resetRunTest();
    if (this.getServiceDef().has_transaction_script) {
      this.updateTransactionScript();
      this.refs.transactionScriptEditor.runTestStart();
    }
  }

  handleRunTestSuccess(data) {
    const serviceDef = this.getServiceDef() || {};
    if (serviceDef.has_transaction_script) {
      if (!data.result) {
        this.handleRunTestError({fields: ['No response received from probe server.']});
        return;
      }
      this.refs.transactionScriptEditor.runTestComplete(data);
      this.setState({testResults: null, testStatus: null});
    } else {
      this.setState({testResults: data, testStatus: data.status, testServer: data.monitoring_server});
    }
  }

  handleRunTestError(data) {
    const fields = data.fields;
    const serviceDef = this.getServiceDef() || {};
    if (fields !== undefined) {
      this.setState({autoloadErrors: data.fields});
    } else {
      const testServerId = data.serverId;
      const server = _.find(this.props.choices.run_test_monitoring_servers, (s) => s.id === parseInt(testServerId));
      this.setState({testResults: data, testStatus: data.status, testServer: server});
    }
    if (serviceDef.has_transaction_script) {
      const validationErrors = (fields || {})['msp_script'];
      this.refs.transactionScriptEditor.runTestComplete(null, validationErrors);
    }
  }

  handleChecksSelectionShowDialog(e) {
    e.preventDefault();
    this.setState({checksSelectorIsActive: true});
  }

  handleChecksSelectionDialogDismissed(e) {
    e.preventDefault();
    this.setState({checksSelectorIsActive: false});
  }

  handleGroupCheckSingleServiceCHange(e) {
    const selectionData = this.refs.singleServiceSelectBox.getSelectionData();
    this.setFormData({group_response_time_single_check_data: selectionData});
  }

  shouldWarnLowNumberOfRetries() {
    // warn if retries == 1 selected
    const {formData} = this.state;
    const numberOfRetries = formData.msp_num_retries;
    return this.isFieldVisible('msp_num_retries') && numberOfRetries <= 1;
  }

  shouldWarnLowSensitivity() {
    // true if sensitivity < 2
    const {formData} = this.state;
    const sensitivity = formData.msp_sensitivity;
    return this.isFieldVisible('msp_sensitivity') && sensitivity && sensitivity < 2;
  }

  shouldWarnLowNumberOfLocations() {
    // true if number of locations < 3 and check is not Pagespeed
    const {formData} = this.state;
    if (formData.monitoring_service_type === 'PAGESPEED') {
      return false;
    }
    const monitoringGroups = formData.monitoring_groups;
    return this.isMonitoringGroupsFieldVisible() && monitoringGroups && monitoringGroups.length < 3;
  }

  shouldWarnSensitivityLocationMismatch() {
    // true if sensitivity >= number of locations
    const {formData} = this.state;
    const sensitivity = formData.msp_sensitivity;
    const monitoringGroups = formData.monitoring_groups;
    return (
      monitoringGroups &&
      this.isMonitoringGroupsFieldVisible() &&
      this.isFieldVisible('msp_sensitivity') &&
      sensitivity >= Math.max(monitoringGroups.length, 2)
    );
  }

  shouldWarnUpdateRumV2Snippet() {
    const formData = this.state.formData;
    if (!formData.id) {
      // Don't show this warning when creating a new check.
      return false;
    }
    const originalDomains = this.state.originalFormData.external_domains;
    const newDomains = formData.external_domains;
    const originalAjaxTrackig = this.state.originalFormData.is_ajax_disabled;
    const newAjaxTracking = formData.is_ajax_disabled;

    return newDomains !== originalDomains || newAjaxTracking !== originalAjaxTrackig;
  }

  shouldWarnMissingContacts() {
    const {formData} = this.state;
    const hasContacts = formData.contact_groups && formData.contact_groups.length > 0;
    return !(hasContacts || !contactGroupsAllowed(formData));
  }

  shouldWarnNoAlertingInAveragingMode() {
    const {formData} = this.state;
    return formData.monitoring_service_type === 'GROUP' && !contactGroupsAllowed(formData);
  }

  shouldWarnMicroTXNTimeout() {
    const {formData} = this.state;
    if (formData.monitoring_service_type === 'TRANSACTION' && formData.msp_interval < 5) {
      return formData.msp_threshold > 30;
    }
  }

  shouldWarnMicroTXNLocations() {
    const {formData} = this.state;
    if (formData.monitoring_service_type === 'TRANSACTION' && formData.msp_interval < 5) {
      return formData.monitoring_groups.length > 3;
    }
  }

  generateWarningList() {
    const warnings = [];
    if (this.shouldWarnLowNumberOfLocations()) {
      warnings.push('We recommend using at least 3 locations per check.');
    }
    if (this.shouldWarnLowSensitivity()) {
      warnings.push('We recommend that check sensitivity is set to 2 or more locations.');
    }
    if (this.shouldWarnLowNumberOfRetries()) {
      warnings.push('We recommend using 2 retries for fast alert times that avoid false positives.');
    }
    if (this.shouldWarnSensitivityLocationMismatch()) {
      warnings.push('We recommend that check sensitivity is set to less than the number of locations.');
    }
    if (this.shouldWarnUpdateRumV2Snippet()) {
      // eslint-disable-next-line max-len
      warnings.push(
        'You must update your snippet for these changes to take effect. "Allowed External Domains" ' +
          'was modified, so the snippet has changed.'
      );
    }
    if (this.shouldWarnMissingContacts()) {
      // eslint-disable-next-line max-len
      warnings.push(
        'This check will not issue alert notifications until a contact is added. ' +
          '(Does not affect configured escalations).'
      );
    }

    if (this.shouldWarnNoAlertingInAveragingMode()) {
      warnings.push(
        <React.Fragment>
          No alerts are issued when you <strong>report on the group’s average uptime %</strong>.
          {/* eslint-disable-next-line max-len */}
          Switch to <strong>Alert when downtime conditions are met</strong> to receive alerts for this group check.
        </React.Fragment>
      );
    }

    if (this.shouldWarnMicroTXNTimeout()) {
      warnings.push(
        <React.Fragment>
          {/* eslint-disable-next-line max-len */}
          Micro-transaction checks with an interval of less than 5 minutes should have a timeout of 30 seconds or less.
        </React.Fragment>
      );
    }

    if (this.shouldWarnMicroTXNLocations()) {
      warnings.push(
        <React.Fragment>
          {/* eslint-disable-next-line max-len */}
          Micro-transaction checks with an interval of less than 5 minutes should have 3 or fewer locations.
        </React.Fragment>
      );
    }
    return warnings;
  }

  renderForm(formDef, formLink, formData) {
    return (
      <React.Fragment>
        <input type="hidden" name="id" value={formData.id || ''} />
        <SectionNav
          sectionIds={this.ALL_PAGES}
          currentSectionId={this.state.currentPage}
          errors={this.getErrorsToDisplay()}
          navClass="nav nav-pills nav-pills-buttons nav-justified mb-4"
          onSectionNav={this.handlePageChange}
        >
          <SectionNavContent sectionId={Pages.PAGE_BASIC} currentSectionId={this.state.currentPage}>
            {this.renderUsageWarning()}
            <FormErrors errors={this.getErrorsToDisplay()['__all__']} />
            {this.renderBasicFields(formDef, formLink, formData)}
          </SectionNavContent>
          <SectionNavContent sectionId={Pages.PAGE_ADVANCED} currentSectionId={this.state.currentPage}>
            {this.renderAdvancedFields(formDef, formLink, formData)}
          </SectionNavContent>
          <SectionNavContent sectionId={Pages.PAGE_ESCALATIONS} currentSectionId={this.state.currentPage}>
            <ServiceEscalations ref="escalations" choices={this.props.choices} errors={this.props.errors} />
          </SectionNavContent>
          <SectionNavContent sectionId={Pages.PAGE_MAINTENANCE} currentSectionId={this.state.currentPage}>
            <ServiceMaintenance
              ref="maintenance"
              choices={this.props.choices}
              errors={this.props.errors}
              formLink={formLink}
              featureFlags={this.props.featureFlags}
            />
          </SectionNavContent>
        </SectionNav>
      </React.Fragment>
    );
  }

  render() {
    const formDef = this.getServiceDef();
    const formLink = prepareFormLink(this, 'formData', this.getErrorsToDisplay());
    const formData = this.getFormData();

    return (
      <Modal
        ref="modal"
        wizard="services-form"
        title={formData.id ? 'Edit Check' : 'Add Check'}
        size="xl"
        keyboard={false}
        saveButton={this.handleSave}
        cancelButton={this.handleModalCancel}
        subModalActive={this.state.checksSelectorIsActive ? 'Select Checks' : null}
        onSubModalDismissed={this.handleChecksSelectionDialogDismissed}
        onShowComplete={this.scrollSnippetIntoView}
      >
        <form className="form-spaced" onSubmit={ReactUtils.doNotSubmit}>
          {this.state.checksSelectorIsActive
            ? this.renderChecksSelectorSubModal(formDef, formLink, formData)
            : this.renderForm(formDef, formLink, formData)}
        </form>
        {this.renderFooterWarning()}
      </Modal>
    );
  }

  renderChecksSelectorSubModal(formDef, formLink) {
    return (
      <ServiceSelectorDialog
        servicesCheckListURL={this.props.extraFormProps.servicesCheckListURL + '?exclude_groups=true'}
        fieldName="group_check_services"
        formLink={formLink}
        maxServices={200}
      />
    );
  }

  renderBasicFields(formDef, formLink, formData) {
    const requiredSectionFields = this.renderFieldSection(formDef, formLink, formData, true);
    const optionalSectionFields = this.renderFieldSection(formDef, formLink, formData, false);
    const hasOptionalFields = !!optionalSectionFields;
    const hasRequiredFields = !!requiredSectionFields;
    const allSections = hasOptionalFields ? this.ALL_SECTIONS : this.ALL_SECTIONS.slice(0, 1);

    return (
      <React.Fragment>
        <TextInput
          fieldName="name"
          labelText="Name of check"
          isRequired={false}
          formLink={formLink}
          wizard="services-form-name"
        />
        {this.SplitIntervalChecks.indexOf(formData.monitoring_service_type) === -1 &&
          this.renderIntervalField(formDef, formLink, formData)}
        <div className="row">
          {this.renderMonitoringServiceTypeField(formDef, formLink, formData)}
          {this.renderContactGroupsField(formDef, formLink, formData)}
        </div>
        <div className="row">
          {this.SplitIntervalChecks.indexOf(formData.monitoring_service_type) !== -1 &&
            this.renderIntervalField(formDef, formLink, formData)}
          {this.renderMonitoringGroupsField(formDef, formLink, formData)}
          {this.renderTagsField(formDef, formLink, formData)}
        </div>
        {formDef && (hasRequiredFields || hasOptionalFields) ? (
          <SectionNav
            sectionIds={allSections}
            currentSectionId={this.state.currentSection}
            errors={this.props.errors}
            onSectionNav={this.handleSectionChange}
          >
            {hasRequiredFields && (
              <SectionNavContent sectionId={Sections.SECTION_REQUIRED} currentSectionId={this.state.currentSection}>
                {requiredSectionFields}
              </SectionNavContent>
            )}
            {hasOptionalFields && (
              <SectionNavContent sectionId={Sections.SECTION_OPTIONAL} currentSectionId={this.state.currentSection}>
                {optionalSectionFields}
              </SectionNavContent>
            )}
          </SectionNav>
        ) : null}
        {this.renderSpecialSection(formDef, formLink, formData)}
        {formDef && formDef.has_feature_run_test ? this.renderRunTest(formDef, formLink, formData) : null}
        {this.renderRunTestResults(formDef, formLink, formData)}
        {this.renderSnippet(formDef, formLink, formData)}
      </React.Fragment>
    );
  }

  renderSplitIntervalField(formDef, formLink, formData) {
    const intervalFieldDef = _.find(formDef.fields, (x) => x.field === 'msp_interval');
    return (
      <React.Fragment>
        <SplitInterval
          minValue={intervalFieldDef.min_value || formDef.min_interval}
          labelText={intervalFieldDef.verbose_name}
          fieldName="msp_interval"
          isRequired={true}
          defaultValue={formDef.min_interval || 5}
          formLink={formLink}
        />
      </React.Fragment>
    );
  }

  renderForcedInterval(intervalLabel, domainHealthURL) {
    return (
      <React.Fragment>
        <Label labelText={'Check interval: ' + intervalLabel} isRequired={true} />
        <div className="form-group">
          <small className="form-text text-muted">
            This check type monitors once every {intervalLabel}. If your check is Down and you believe it should be Up,
            use the&nbsp;
            <a href={domainHealthURL} target="_blank">
              Monitor Entire Site Tool
            </a>
            &nbsp; to test or confirm check status.
          </small>
        </div>
      </React.Fragment>
    );
  }

  getHoursLabel(hours) {
    if (!hours) {
      return '';
    }
    return `${hours} ${Formatter.pluralize(hours, 'hour', 'hours')}`;
  }

  renderSliderIntervalField(formDef, formLink, formData) {
    const {monitoring_service_type: mst} = formData;
    const {fields} = this.props.choices.service_defs[mst];
    const msp_interval = fields.find((f) => f.field === 'msp_interval' && !!f.mapped_fields.frequency) || {
      mapped_fields: {frequency: {}},
    };
    const min = msp_interval.mapped_fields.frequency[this.props.checkFrequency] || this.props.choices.msp_interval.min;
    const hour = [60, 1440].indexOf(min) !== -1;
    const max = hour ? 60 * 24 : 60;
    const value = hour ? formData.msp_interval / 60 : formData.msp_interval;
    const suffix = hour ? 'hours' : 'minutes';
    const step = hour ? 60 : 1;

    // no options when only 1 option is available
    if (min === max) {
      const hours = Math.floor(min / 60);
      const minutes = min - hours * 60;
      const label = this.getHoursLabel(hours) + (hours && minutes ? ' ' : '') + (minutes ? minutes + ' minutes' : '');
      return this.renderForcedInterval(label, this.props.extraFormProps.domainHealthURL);
    }

    return (
      <React.Fragment>
        <Label
          wizard="services-form-msp_interval-label"
          labelText={`Check interval: ${value} ${suffix}`}
          isRequired={true}
        />
        <Slider fieldName="msp_interval" step={step} min={min} max={max} isRequired={false} formLink={formLink} />
      </React.Fragment>
    );
  }

  renderIntervalField(formDef, formLink, formData) {
    if (!formDef || formDef.force_interval === 0) {
      return null;
    }

    if (formDef.force_interval !== null) {
      const hours = Math.floor(formDef.force_interval / 60);
      const minutes = formDef.force_interval - hours * 60;
      const label = this.getHoursLabel(hours) + (hours && minutes ? ' ' : '') + (minutes ? minutes + ' minutes' : '');
      return this.renderForcedInterval(label, this.props.extraFormProps.domainHealthURL);
    }
    if (this.SplitIntervalChecks.indexOf(formData.monitoring_service_type) !== -1) {
      return this.renderSplitIntervalField(formDef, formLink, formData);
    } else {
      return this.renderSliderIntervalField(formDef, formLink, formData);
    }
  }

  renderHelpText(formDef) {
    let helpText = formDef ? formDef.help_text : null;
    let link = null;
    const supportLink = formDef ? formDef.support_link : null;

    if (!!helpText && !helpText.endsWith('.')) {
      helpText += '.';
    }

    if (supportLink) {
      link = <ZendeskSupportLink href={supportLink}>View&nbsp;Documentation&nbsp;&rarr;</ZendeskSupportLink>;
    }

    return (
      <React.Fragment>
        <span>{helpText} </span>
        {link}
      </React.Fragment>
    );
  }

  renderMonitoringServiceTypeField(formDef, formLink, formData) {
    var groups = this.props.choices.monitoring_service_type;
    let helpText = this.renderHelpText(formDef);

    if (this.TxnRecorderChecks.includes(formData.monitoring_service_type)) {
      helpText = (
        <React.Fragment>
          {helpText}
          <div>
            Install our Free Chrome Transaction Recorder.{' '}
            <a
              href="https://chromewebstore.google.com/detail/uptimecom-transaction-rec/jiiejololhdhdhfefdaokjkgfpmdbiio"
              target="_blank"
            >
              Get the Recorder Here &rarr;
            </a>
          </div>
        </React.Fragment>
      );
    }

    if (!formData.id && groups) {
      var filteredGroups = {};
      for (const [group, entries] of Object.entries(groups)) {
        if (group != 'Third Party') {
          filteredGroups[group] = [];
          for (let i = 0; i < entries.length; i++) {
            const mst = entries[i][0];
            if (mst != 'CLOUDSTATUS') {
              filteredGroups[group].push(entries[i]);
            }
          }
        }
      }
      groups = filteredGroups;
    }

    return (
      <div className="col-sm-6">
        <Select2
          fieldName="monitoring_service_type"
          disabled={!!formData.id}
          groups={groups}
          labelText="Check type"
          helpText={helpText}
          isRequired={true}
          preventSearch={true}
          formLink={formLink}
          onChange={this.handleTypeChanged}
        />
      </div>
    );
  }

  renderContactGroupsField(formDef, formLink, formData) {
    const contactsAllowed = contactGroupsAllowed(formData);
    const helpText = contactsAllowed
      ? null
      : 'Reporting on the group’s average uptime% will not issue alerts for downtime within this group check.';
    return (
      <div className="col-sm-6">
        <Select2
          fieldName="contact_groups"
          choices={contactsAllowed ? this.props.choices.contact_groups : null}
          disabled={!contactsAllowed}
          helpText={helpText}
          labelText="Contacts"
          isRequired={true}
          multiple={true}
          closeOnSelect={false}
          formLink={formLink}
          wizard="services-form-contact_groups"
        />
      </div>
    );
  }

  renderMonitoringGroupsField(formDef, formLink, formData) {
    if (!formDef || !this.isMonitoringGroupsFieldVisible()) {
      return null;
    }

    const monitoringGroupError = formLink.errors('monitoring_groups');
    const isSingleLocation = formDef && formDef.force_monitoring_group_limit === 1;
    const groupTypes = [formDef.force_monitoring_group_type || 'NORMAL'];
    if (formData.monitoring_service_type == 'TRANSACTION') {
      groupTypes.push('TRANSACTION_EXT');
    }
    let monitoringGroups = this.props.choices.monitoring_groups.filter(
      (mg) => groupTypes.includes(mg.type) || mg.is_private
    );

    monitoringGroups = this.filterAllowedMonitoringGroupsForCheckType(
      formData.monitoring_service_type,
      formData.msp_version,
      monitoringGroups,
      (mg) => mg.id
    );

    const value = formData.monitoring_groups || [];
    const selectedGroups = monitoringGroups.filter((mg) => value.indexOf(mg.id) !== -1);

    const LocationOption = ({data, ...props}) => {
      const flag = this.props.choices.monitoring_group_to_country[data.id];
      return (
        <components.Option {...props} data={data}>
          <span className="d-flex">
            <span className="mr-auto">
              {flag && <span className={`mr-2 flag-icon flag-icon-${flag}`} />}
              <small>{data.location}</small>
            </span>
            <span>{data.code}</span>
          </span>
        </components.Option>
      );
    };

    const SingleValue = ({children, data, ...props}) => {
      const flag = this.props.choices.monitoring_group_to_country[data.id];
      return (
        <components.SingleValue {...props} data={data}>
          {flag && <span className={`mr-2 flag-icon flag-icon-${flag}`} />}
          {children}
        </components.SingleValue>
      );
    };

    const MultiValueLabel = ({children, data, ...props}) => {
      const flag = this.props.choices.monitoring_group_to_country[data.id];
      return (
        <components.MultiValueLabel {...props} data={data} className="cursor-text">
          {flag && <span className={`mr-2 flag-icon flag-icon-${flag}`} />}
          {children}
        </components.MultiValueLabel>
      );
    };

    return (
      <div className="col-sm-6">
        <FormLabel required>{isSingleLocation ? 'Location' : 'Locations'}</FormLabel>
        <Select
          name="monitoring_groups"
          classNamePrefix="uptime_select"
          className="mb-1"
          styles={selectStyle}
          options={monitoringGroups}
          value={selectedGroups}
          getOptionLabel={(mg) => mg.location}
          getOptionValue={(mg) => mg.id}
          onChange={(data) => {
            let value = [];
            if (!_.isArray(data)) {
              value = data ? [data.id] : [];
            } else {
              value = data.map((mg) => mg.id);
            }

            const formLinkEvent = {
              target: {
                type: 'formLink',
                value: value,
              },
            };
            // value is always an array
            formLink.forField('monitoring_groups').set(formLinkEvent);
          }}
          isMulti={!isSingleLocation}
          required={true}
          isClearable={true}
          disabled={this.props.choices.monitoring_groups_unselectable}
          components={{SingleValue, MultiValueLabel, Option: LocationOption}}
        />
        <FieldErrors errors={monitoringGroupError} />
        <small>
          <a href={this.props.extraFormProps.probeServersListURL} target="_blank">
            View&nbsp;Probe&nbsp;Servers
          </a>{' '}
          list for whitelisting IP's &rarr;
        </small>
      </div>
    );
  }

  renderTagsField(formDef, formLink) {
    const tags = this.props.choices.tags || [];
    const choices = _.map(tags, function (tag) {
      return [tag.id, tag.tag];
    });
    const helpText = this.renderHelpText({
      help_text: 'Manage check tags',
      support_link: 'https://support.uptime.com/hc/en-us/articles/360001254399-Tags-and-Filtering-Basics',
    });
    return (
      <div className="col-sm-6">
        <Select2
          fieldName="tags"
          choices={choices}
          labelText="Tags"
          isRequired={false}
          multiple={true}
          closeOnSelect={false}
          helpText={helpText}
          titleText="Apply one or more tags to this check."
          formLink={formLink}
        />
      </div>
    );
  }

  renderGroupTagsField(formDef, formLink) {
    const fieldName = 'group_check_tags';
    const fieldInfo = _.find(formDef.fields, {field: fieldName});
    const tags = this.props.choices.tags || [];
    const choices = _.map(tags, function (tag) {
      return [tag.id, tag.tag];
    });
    return (
      <div className="col-sm-6" key="group-tags-selector">
        <Select2
          fieldName={fieldName}
          choices={choices}
          labelText={fieldInfo.verbose_name}
          titleText={fieldInfo.title}
          placeholder="All tags..."
          allowEmpty={true}
          isRequired={false}
          multiple={true}
          closeOnSelect={false}
          formLink={formLink}
        />
      </div>
    );
  }

  renderGroupCheckIdsField(formDef, formLink, formData) {
    const fieldName = 'group_check_services';
    const fieldInfo = _.find(formDef.fields, {field: fieldName});
    const field = formData[fieldName];
    const numChecks = field ? field.length : 0;
    return (
      <div className="col-sm-6" key="group-check-selector">
        <ServiceSelectorInput
          numChecks={numChecks}
          fieldName={fieldName}
          labelText={fieldInfo.verbose_name}
          titleText={fieldInfo.title}
          isRequired={false}
          onShowDialog={this.handleChecksSelectionShowDialog}
        />
      </div>
    );
  }

  renderGroupCheckSingleCheckField(fieldInfo, formLink, formData) {
    const fieldName = 'group_response_time_single_check';
    const initialData = formData['group_response_time_single_check_data'];
    let initialChoices = null;
    if (initialData) {
      initialChoices = [[initialData.id, `${initialData.name}~!~${initialData.address}~!~${initialData.type}`]];
    }

    return (
      <SingleServiceSelectBox
        ref="singleServiceSelectBox"
        servicesCheckListURL={this.props.extraFormProps.servicesCheckListURL + '?with_response_time=true'}
        ajaxPreload={true}
        minimumResultsForSearch={0}
        ajaxLimit={50}
        fieldName={fieldName}
        labelText={fieldInfo.verbose_name}
        titleText={fieldInfo.title}
        isRequired={false}
        formLink={formLink}
        onChange={this.handleGroupCheckSingleServiceCHange}
        ajaxInitialChoices={initialChoices}
      />
    );
  }

  renderFieldSection(formDef, formLink, formData, isForRequiredFields) {
    if (!formDef) {
      return null;
    }

    const ignores = ['msp_interval', 'msp_script', 'group_check_tags', 'group_check_services'];
    const forceRequired = ['DNS|msp_dns_server', 'DNS|msp_expect_string'];

    const predicate = (fieldInfo) => {
      const fieldType = formData.monitoring_service_type + '|' + fieldInfo.field;
      const ignored = ignores.indexOf(fieldInfo.field) >= 0;
      const fieldInRequiredSection = !fieldInfo.optional || forceRequired.indexOf(fieldType) >= 0;
      const fieldInSelectedCheckVersion = this.isFieldIncludedInCheckVersion(fieldInfo, formData);

      return (
        !fieldInfo.advanced && !ignored && fieldInRequiredSection === isForRequiredFields && fieldInSelectedCheckVersion
      );
    };

    const fields = formDef.fields.filter(predicate);

    if (fields.length === 0) {
      return null;
    }

    const fullWidth = [
      'WHOIS|msp_expect_string',
      'SSL_CERT|ssl_cert_crl',
      'SSL_CERT|ssl_cert_first_element_only',
      'SSL_CERT|ssl_cert_selfsigned',
      'SSL_CERT|ssl_cert_match',
      'SSL_CERT|ssl_ignore_authority_warnings',
      'SSL_CERT|ssl_ignore_sct',
    ];

    const renderedFields = fields.map((fieldInfo) => {
      const fieldType = formData.monitoring_service_type + '|' + fieldInfo.field;
      const cssClass = fields.length === 1 || fullWidth.indexOf(fieldType) >= 0 ? 'col-sm-12' : 'col-sm-6';
      const field = this.renderField(fieldInfo, formLink, formData);
      if (!field) {
        return false;
      }
      return (
        <div key={fieldInfo.field + '-wrapper'} className={cssClass}>
          {field}
        </div>
      );
    });

    if (formData.monitoring_service_type === 'GROUP' && isForRequiredFields) {
      renderedFields.unshift(this.renderGroupTagsField(formDef, formLink, formData));
      renderedFields.unshift(this.renderGroupCheckIdsField(formDef, formLink, formData));
    }
    return <div className="row">{renderedFields}</div>;
  }

  renderAdvancedFields(formDef, formLink, formData) {
    if (!formDef) {
      return null;
    }

    const fields = [];
    const textareas = [];
    const checkboxes = [];

    const predicate = (fieldInfo) => fieldInfo.advanced && this.isFieldIncludedInCheckVersion(fieldInfo, formData);

    formDef.fields.filter(predicate).forEach((fieldInfo) => {
      const renderedField = this.renderField(fieldInfo, formLink, formData);

      if (renderedField.type === TextArea) {
        textareas.push(renderedField);
      } else if (renderedField.type === 'div') {
        checkboxes.push(renderedField);
      } else {
        fields.push(renderedField);
      }
    });

    return (
      <React.Fragment>
        <div className="row">
          {fields.map((e) => (
            <div key={e.key + '-wrapper'} className="col-sm-6">
              {e}
            </div>
          ))}
        </div>
        <div>
          {textareas}
          {checkboxes}
        </div>
      </React.Fragment>
    );
  }

  renderField(fieldInfo, formLink, formData) {
    const fieldProps = {
      key: fieldInfo.field,
      fieldName: fieldInfo.field,
      labelText: fieldInfo.verbose_name,
      titleText: fieldInfo.title,
      helpText: this.renderHelpText(fieldInfo),
      isRequired: !fieldInfo.optional,
      formLink: formLink,
      wizard: `services-form-${fieldInfo.field}`,
    };

    let element = TextInput;
    const selects = [
      'monitoring_group',
      'msp_version',
      'msp_sensitivity',
      'msp_num_retries',
      'monitoring_service_type',
      'msp_dns_record_type',
      'msp_encryption',
      'msp_expect_string_type',
      'msp_use_ip_version',
      'aggregation_type',
      'group_check_down_condition',
      'group_response_time_calculation_mode',
      'group_response_time_check_type',
      'connection_throttling',
      'emulated_device',
      'uptime_grade_threshold',
      'ssl_cert_minimum_ssl_tls_version',
      'ssl_cert_protocol',
    ];
    const radioButtons = ['group_uptime_percent_calculation'];
    const textareas = [
      'msp_headers',
      'msp_notes',
      'url_groups',
      'exclude_useragents',
      'external_domains',
      'exclude_urls',
      'ssl_cert_match',
    ];
    const booleans = [
      'msp_include_in_global_metrics',
      'group_negate_check_logic',
      'ssl_cert_crl',
      'ssl_cert_first_element_only',
      'ssl_cert_selfsigned',
      'ssl_ignore_authority_warnings',
      'ssl_ignore_sct',
      'is_ajax_disabled',
    ];
    const passwords = ['msp_password'];

    // This is a special case - we need to hide group down condition field in case if averaging
    // mode is selected (because the group check does not support alerting in this mode, and
    // this setting does not make sense
    if (
      fieldInfo.field === 'group_check_down_condition' &&
      formData.group_uptime_percent_calculation !== GroupUptimePercentCalculation.UP_DOWN_STATES
    ) {
      return false;
    }

    if (
      fieldInfo.field === 'group_response_time_check_type' &&
      formData.group_response_time_calculation_mode !== 'AVERAGE'
    ) {
      return false;
    }

    if (fieldInfo.field === 'group_response_time_single_check') {
      if (formData.group_response_time_calculation_mode !== 'COPY') {
        return false;
      } else {
        return this.renderGroupCheckSingleCheckField(fieldInfo, formLink, formData);
      }
    }

    if (selects.indexOf(fieldInfo.field) >= 0) {
      element = Select2;
      fieldProps.choices = this.props.choices[fieldInfo.field];
    }

    if (passwords.indexOf(fieldInfo.field) >= 0) {
      element = ShowPassword;
    }

    if (booleans.indexOf(fieldInfo.field) >= 0) {
      element = CheckBox;
    }

    if (radioButtons.indexOf(fieldInfo.field) >= 0) {
      element = RadioGroup;
      fieldProps.choices = this.props.choices[fieldInfo.field];
      if ('group_uptime_percent_calculation' === fieldInfo.field) {
        const groupDesc = this.props.choices.group_uptime_percent_calculation_descriptions;
        const groupChoices = this.props.choices[fieldInfo.field].map((ch) => {
          return [
            ch[0],
            <React.Fragment>
              {ch[1]}
              <HelpIcon titleText={groupDesc[ch[0]]} />
            </React.Fragment>,
          ];
        });
        Object.assign(fieldProps, {
          choices: groupChoices,
          baseCSSClass: 'form-control-label', // applies to title Label
          divCSSClass: 'mt-2', // applies to RadioGroup options
          formGroupClass: 'pb-2', // applies to RadioGroup container
          isRequired: false, // technically, it is required; just to remove bold
        });
      }
    }

    // visual adjustments for Group Check
    if (
      [
        'group_check_down_condition',
        'group_response_time_calculation_mode',
        'group_response_time_single_check',
        'group_response_time_check_type',
      ].includes(fieldInfo.field)
    ) {
      fieldProps.isRequired = false; // technically, it is required; just to remove bold
      if ('group_check_down_condition' === fieldInfo.field) {
        fieldProps.baseCSSClass = 'form-control-label mb-3';
      }
    }

    if ('msp_version' === fieldInfo.field) {
      const serviceDef = this.getServiceDef();
      if (serviceDef) {
        fieldProps.choices = serviceDef.versions.map((v) => [v.version, v.name + '~!~' + v.description]).reverse();
        fieldProps.templateSelection = (x) => x.text.split('~!~')[0];
        fieldProps.templateResult = (x) =>
          $(`
                    <div class="font-14">
                      <div>${x.text.split('~!~')[0]}</div>
                      <div class="small text-muted">${x.text.split('~!~')[1] || ''}</div>
                    </div>
                `);
      } else {
        fieldProps.choices = [];
      }
    } else if ('msp_address' === fieldInfo.field) {
      fieldProps.onBlur = this.handleAddressBlur;
    } else if ('msp_uptime_sla' === fieldInfo.field) {
      element = PercentageInput;
    } else if (
      'DNS' === formData.monitoring_service_type &&
      ('msp_expect_string' === fieldInfo.field || 'msp_dns_server' === fieldInfo.field)
    ) {
      element = TextInput;
      fieldProps.addonLabel = 'Autoload';
      fieldProps.onAddonClick = this.handleDNSAutoload;
    } else if ('WHOIS' === formData.monitoring_service_type && 'msp_expect_string' === fieldInfo.field) {
      element = TextArea;
      fieldProps.readOnly = true;
      fieldProps.small = true;
      fieldProps.rows = 5;
      fieldProps.addonLabel = 'Refresh';
      fieldProps.onAddonClick = this.handleWhoisAutoload;
    } else if (textareas.indexOf(fieldInfo.field) >= 0) {
      element = TextArea;
    }

    const renderedElement = React.createElement(element, fieldProps);
    if (element === CheckBox) {
      return (
        <div key={fieldInfo.field + '-wrapper'} className="form-group">
          {renderedElement}
        </div>
      );
    } else {
      return renderedElement;
    }
  }

  renderSpecialSection(formDef, formLink, formData) {
    if (!formDef) {
      return null;
    }
    const validation_errors = formLink.forField('msp_script').errors() || null;
    let onOpenRecorder = null;
    if (this.TxnRecorderChecks.includes(formData.monitoring_service_type)) {
      onOpenRecorder = this.handleOpenRecorder;
    }
    return (
      <TransactionScriptEditor
        ref="transactionScriptEditor"
        testURL={this.props.extraFormProps.transactionTestURL}
        pagespeedReportURL={this.props.extraFormProps.pagespeedReportURL}
        onOpenRecorder={onOpenRecorder}
        isVisible={formDef.has_transaction_script}
        validationErrors={validation_errors}
        stepDefs={this.props.stepDefs}
      />
    );
  }

  renderRunTest(formDef, formLink, formData) {
    return (
      <React.Fragment>
        <hr className="px-0" />
        <div className="mb-1 pt-3">
          <TestRunner
            locations={this.listTestLocations(formDef.can_run_test_on_public)}
            onStart={this.handleRunTestStart}
            onSuccess={this.handleRunTestSuccess}
            onError={this.handleRunTestError}
            data={this.getFormData()}
            scriptGetter={this.getTransactionScript}
            helpText={
              formDef.can_run_test_on_public
                ? 'This test is running from the Uptime.com probe server you selected.'
                : 'This test is running from a dedicated Uptime.com testing server or private location.'
            }
            runTestURL={this.props.extraFormProps.runTestURL}
          />
        </div>
      </React.Fragment>
    );
  }

  renderRunTestResults() {
    if (this.state.testStatus !== TaskStatus.FAILED && this.state.testStatus !== TaskStatus.COMPLETED) {
      return null;
    }
    var result = this.state.testResults.result;
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <p className="font-14 text-muted mr-3 mb-0">
            <em>{this.state.testServer.name || ''}</em>
          </p>
          <p className="d-none d-md-block font-13 font-monospace text-muted text-prewrap mb-0">
            [{this.state.testServer.address_ipv4 || ''}]&nbsp; [{this.state.testServer.address_ipv6 || ''}]
          </p>
        </div>
        <div className={'mb-2 alert alert-' + (this.state.testStatus === TaskStatus.COMPLETED ? 'success' : 'danger')}>
          <strong>{this.state.testStatus === TaskStatus.COMPLETED ? 'Success' : 'Error'}</strong>
          <br />
          <code style={{color: 'inherit'}}>{result}</code>
        </div>
      </React.Fragment>
    );
  }

  preClick(e) {
    var range = document.createRange();
    range.selectNodeContents(e.target);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  renderSnippet(formDef, formLink, formData) {
    const servicesWithSnippets = ['RUM2', 'HEARTBEAT', 'WEBHOOK'];
    const serviceType = formData.monitoring_service_type;
    if (!formData.uuid || !servicesWithSnippets.includes(formData.monitoring_service_type)) {
      return null;
    }
    if (serviceType === 'RUM2') {
      var ext_domains = '',
        ajax_disabled = '';
      if (formData.external_domains) {
        const domains = formData.external_domains.split('\n');
        for (let i = 0; i < domains.length; i++) {
          domains[i] = '"' + domains[i].trim() + '"';
        }
        ext_domains = 'w._uptime_rum2.ext=[' + domains.join(',') + '];';
      }
      if (formData.is_ajax_disabled) {
        ajax_disabled = 'w._uptime_rum2.ajax=false;';
      }
      return this.renderSnippetBody(
        'RUM Code For Your Site',
        <React.Fragment>
          {/* eslint-disable-next-line max-len */}
          <strong>
            Place the HTML code snippet before any scripts you wish to track with RUM (usually within the &lt;head&gt;
            attributes).
          </strong>
          <br />
          {/* eslint-disable-next-line max-len */}
          <span>
            This will enable RUM checks on all pages where this snippet appears. It will not affect your site's page
            load time.
          </span>
        </React.Fragment>,
        this.props.choices.rum2_snippet
          .replace('##UUID##', formData.uuid)
          .replace('###EXTD###', ext_domains)
          .replace('###AJAX###', ajax_disabled),
        'rum-code'
      );
    } else {
      let snippet;
      if (serviceType === 'HEARTBEAT') {
        snippet = this.props.choices.heartbeat_snippet;
      } else {
        snippet = this.props.choices.webhook_snippet;
      }
      return this.renderSnippetBody(
        Formatter.titleCase(serviceType) + ' URL For Your Check',
        <React.Fragment>
          <strong>Use the following URL to send data to your check:</strong>
        </React.Fragment>,
        snippet.url.replace('##UUID##', formData.uuid),
        'custom-check-code',
        <React.Fragment>
          <p className="small mb-2">
            <strong>Example using cURL:</strong>
          </p>
          <pre className="card card-body-slim bg-light-2" onClick={this.preClick}>
            {snippet.snippet1.replace('##UUID##', formData.uuid)}
          </pre>
          <p className="small mb-2">
            <strong>Example using cURL with timeseries metrics:</strong>
          </p>
          <pre className="card card-body-slim bg-light-2" onClick={this.preClick}>
            {snippet.snippet2.replace('##UUID##', formData.uuid)}
          </pre>
        </React.Fragment>
      );
    }
  }

  renderSnippetBody(title, description, snippet, id, tooltip) {
    // We use the validation errors object to indicate a new RUM code was
    // generated and an alert needs to be displayed.
    var infoBar = null;
    if (this.props.errors.snippet) {
      infoBar = (
        <div className="alert alert-info">
          <i className="fas fa-info-circle mr-2" />
          {this.props.errors.snippet}
        </div>
      );
    }

    if (!id) {
      id = '';
    }

    return (
      <div className="mt-3">
        <h5 id={id} className="mb-2">
          {title}
        </h5>
        <hr className="mt-0 mb-3" />
        {infoBar}
        <p className="small mb-2">{description}</p>
        <pre className="card card-body-slim bg-light-2" onClick={this.preClick}>
          {snippet}
        </pre>
        {tooltip ? tooltip : null}
      </div>
    );
  }

  renderFooterWarning() {
    const warnings = this.generateWarningList();
    return (
      <div className="mt-3">
        <FormWarning warnings={warnings} warningTitle="Attention!" />
      </div>
    );
  }

  renderUsageWarning() {
    const errors = this.getErrorsToDisplay();
    const {formData} = this.state;
    const serviceType = formData.monitoring_service_type;
    const {accountUsage} = this.props;
    if (
      !formData ||
      formData.id ||
      !serviceType ||
      (errors && errors.__all__) ||
      !accountUsage ||
      !accountUsage[serviceType]
    ) {
      return null;
    }
    const usageData = accountUsage[serviceType];
    const planLimit = this.props.limits[usageData.limit_field];
    return (
      <InstantCheckout
        plan={this.props.plan}
        planLimit={planLimit}
        sessionID={this.state.sessionID}
        isPrimary={this.props.isPrimary}
        userCanPurchase={this.props.userCanPurchase}
        accountCanPurchase={this.props.accountCanPurchase}
        usageData={usageData}
        contactUsURL={this.props.contactUsURL}
        manageAddOnsURL={this.props.manageAddOnsURL}
        manageSubscriptionURL={this.props.manageSubscriptionURL}
        invoicesURL={this.props.invoicesURL}
        purchaseURL={this.props.purchaseURL}
        purchaseRequestURL={this.props.purchaseRequestURL}
      />
    );
  }
}
